import * as React from 'react';

import Seo from '../components/seo';

const IndexPage = () => (
  <>
    <Seo title="SPA-sites" language="en" />
    <h1 className="flex flex--center">SPA-Websites</h1>
  </>
);

export default IndexPage;
